import React, { ComponentType, Suspense, lazy, useState } from 'react'
import has from 'lodash/has'
import { observer } from 'mobx-react'
import Badge from 'react-bootstrap/Badge'
import Dropdown from 'react-bootstrap/Dropdown'
import { ModalProps } from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Navbar from 'react-bootstrap/Navbar'
import { Bell, LifeBuoy } from 'react-feather'
import styled from 'styled-components'

import GetPackageIdsModal from 'src/components/GetPackageIdsModal'
import GetUnitIdsModal from 'src/components/GetUnitIdsModal'
import HelpModal from 'src/components/HelpModal'
import Link from 'src/components/Link'
import Notification from 'src/components/Notification'
import PrintUnitIdsModal from 'src/components/PrintUnitIdsModal'
import { ButtonVariant, Variant } from 'src/constants'
import { FeatureFlagName } from 'src/models/config'
import AuthStore from 'src/stores/AuthStore'
import GlobalStore from 'src/stores/GlobalStore'
import NotificationsStore from 'src/stores/NotificationsStore'
import ViewStore, { ViewId } from 'src/stores/ViewStore'

import ChristmasTreeImage from 'src/images/christmas-tree.png' // Import the image

const ZippyMarquee = lazy(() => import('src/components/ZippyMarquee'))

interface Link {
  label: string
  href?: string
  view?: ViewId
  onClick?: () => void
}

interface MenuItem extends Link {
  dropdownLinks?: Link[]
  featureFlag?: FeatureFlagName
}

const NavbarSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 12px;
`

const NavLink: React.FC<{
  as?: ComponentType
  link: Link
}> = observer(({ as, link }) => {
  const Wrapper = as || Nav.Link
  return (
    <Wrapper
      active={ViewStore.currentView.id === link.view}
      key={link.label}
      href={link.href}
      onClick={
        link.view === undefined
          ? link.onClick
          : () => ViewStore.showView(link.view!)
      }
    >
      {link.label}
    </Wrapper>
  )
})

enum ModalType {
  UNIT_IDS = 0,
  PACKAGE_IDS = 1,
  HELP = 2,
  PRINT_IDS = 3,
}

const ModalTypeToComponentMap: Record<ModalType, React.FC<ModalProps>> = {
  [ModalType.UNIT_IDS]: GetUnitIdsModal,
  [ModalType.PACKAGE_IDS]: GetPackageIdsModal,
  [ModalType.HELP]: HelpModal,
  [ModalType.PRINT_IDS]: PrintUnitIdsModal,
}

const AppNavbar: React.FC = observer(() => {
  const [visibleModal, setVisibleModal] = useState<ModalType | undefined>()

  const notifications = NotificationsStore.sortedNotifications

  const PRIMARY_MENU_ITEMS: MenuItem[] = [
    { label: 'Customer Service View', view: ViewId.CUSTOMER_SERVICE },
    {
      label: 'Packaging',
      view: ViewId.PACKAGING,
    },
    {
      label: 'Inventory',
      dropdownLinks: [
        {
          label: 'Add Units',
          view: ViewId.ADD_UNITS,
        },
        {
          label: 'Return Units',
          view: ViewId.RETURN_UNITS,
        },
        {
          label: 'Unit Statuses',
          view: ViewId.UNITS,
        },
        {
          label: 'Inventory',
          view: ViewId.INVENTORY,
        },
        {
          label: 'Audit',
          view: ViewId.AUDIT,
        },
        {
          label: 'Expiry Report',
          view: ViewId.EXPIRY_REPORT,
        },
      ],
    },
    {
      label: 'Download IDs',
      dropdownLinks: [
        {
          label: 'Unit IDs',
          onClick: () => setVisibleModal(ModalType.UNIT_IDS),
        },
        {
          label: 'Package IDs',
          onClick: () => setVisibleModal(ModalType.PACKAGE_IDS),
        },
        {
          label: 'Print IDs',
          onClick: () => setVisibleModal(ModalType.PRINT_IDS),
        },
      ],
    },
    {
      label: 'Summary & Reports',
      view: ViewId.SUMMARY_AND_REPORTS,
    },
    { label: 'Admin', view: ViewId.ADMIN_LINKS },
  ]

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center">
{GlobalStore.getFeatureFlag(FeatureFlagName.FESTIVAL_ENABLED) && (
            <img
              src={ChristmasTreeImage}
              alt="Christmas Tree"
              style={{ width: '70px', marginRight: '10px' }}
              title="Happy holidays!!"
            />
          )}
        Fulfillment System
          </div>
          {GlobalStore.uiConfig &&
            GlobalStore.uiConfig.distributionCenterName && (
              <NavbarSubtitle>
                {GlobalStore.uiConfig.distributionCenterName}
              </NavbarSubtitle>
            )}
        </Navbar.Brand>

        {/* Left-aligned menu */}
        <Nav className="mr-auto">
          {PRIMARY_MENU_ITEMS.map((item: MenuItem) =>
            has(item, 'dropdownLinks') ? (
              <NavDropdown key={item.label} id={item.label} title={item.label}>
                {item.dropdownLinks!.map((link) => (
                  <NavLink key={link.label} as={NavDropdown.Item} link={link} />
                ))}
              </NavDropdown>
            ) : (
              <NavLink key={item.label} link={item} />
            )
          )}
        </Nav>

        {/* Right-aligned menu */}
        <Navbar expand="sm" className="justify-content-end">
          <Nav>
            <Nav.Link onClick={() => setVisibleModal(ModalType.HELP)}>
              <LifeBuoy size={24} color="var(--danger)" /> Get Help
            </Nav.Link>

            <Dropdown alignRight>
              <Dropdown.Toggle id="notifications" variant={ButtonVariant.LINK}>
                <Badge
                  pill
                  variant={
                    NotificationsStore.has_new_order_notification()
                      ? Variant.DANGER
                      : notifications.length > 0
                        ? Variant.PRIMARY
                        : Variant.SECONDARY
                  }
                >
                  <Bell size={16} /> {notifications.length}
                </Badge>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Nav.Item className="px-4 py-2 text-sm d-flex">
                  <Link
                    className="ml-auto"
                    newWindow
                    href="/admin/notification/notification"
                  >
                    Manage
                  </Link>
                </Nav.Item>

                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <Nav.Link
                      as={Notification}
                      key={notification.id}
                      notification={notification}
                    />
                  ))
                ) : (
                  <Nav.Item className="text-muted px-4 py-2">
                    No unread notifications!
                  </Nav.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>

            {AuthStore.currentUser && (
              <NavDropdown
                id="account"
                title={
                  <>
                    Welcome, <strong>{AuthStore.currentUser.name}</strong>
                  </>
                }
              >
                <NavLink
                  as={NavDropdown.Item}
                  link={{ label: 'Log Out', onClick: () => AuthStore.logout() }}
                />
              </NavDropdown>
            )}
          </Nav>
        </Navbar>
      </Navbar>

      {GlobalStore.uiConfig && GlobalStore.uiConfig.globalAlertMessage && (
        <Suspense fallback={null}>
          <ZippyMarquee>{GlobalStore.uiConfig.globalAlertMessage}</ZippyMarquee>
        </Suspense>
      )}

      {[
        ModalType.UNIT_IDS,
        ModalType.PACKAGE_IDS,
        ModalType.HELP,
        ModalType.PRINT_IDS,
      ].map((modalType) => {
        const Modal = ModalTypeToComponentMap[modalType]
        return (
          Modal && (
            <Modal
              key={modalType}
              show={visibleModal === modalType}
              onHide={() => setVisibleModal(undefined)}
            />
          )
        )
      })}
    </>
  )
})

export default AppNavbar