import { ProductGroup } from 'src/constants'
import { PackageType } from 'src/models/nest'
import { OrderPreview, RequestedProduct } from 'src/models/order'
import { Package } from 'src/models/package'
import { BasicUnitWithProduct } from 'src/models/unit'
import { FulfillmentOperator } from 'src/models/users'

/** Keep this in sync with backend-django/ordering/models/shipment.py. */
export enum Status {
  CANCELED = 'canceled',
  CANCELED_NEEDS_UNPACKING = 'canceled_needs_unpacking',
  WAITING_FOR_PACKING = 'waiting_for_packing',
  PACKING = 'packing',
  WAITING_FOR_COMMIT = 'waiting_for_commit',
  ALTERNATE_DELIVERING = 'alternate_delivering',
  LAUNCHING = 'launching',
  ENROUTE = 'enroute',
  DELIVERED = 'delivered',
  FAILED_TO_DELIVER = 'failed_to_deliver',
}

export enum ApiStatus {
  LOADING = 'loading',
  LOADED = 'loaded',
  UNLOADED = 'unloaded',
  ERROR = 'error',
}

export interface ShipmentPreview {
  id: string
  order: OrderPreview
  productGroups?: ProductGroup[]
  timeReceived: string
  packers: FulfillmentOperator[]
  idInOrder?: number
  packageIds?: string[]
  status: Status
  timeScheduled?: string
  launchWindowStart?: string
  launchWindowEnd?: string
  launchIsUrgentAfter?: string
  timeBeginPacking?: string
  clearForLaunch?: boolean | null
  windSpeed?: number | null
  batteryDotRating?: number | null
}

export interface ShipmentPreviewUnits
  extends Omit<ShipmentPreview, 'order' | 'status'> {
  order?: OrderPreview
  package_id?: string
  status?: Status
  locked: boolean
  shipmentUnits: ShipmentUnit[]
  rescheduleReason?: string
}

export interface Shipment extends ShipmentPreviewUnits {
  order: OrderPreview
  status: Status
  timeReceived: string
  timeStartedPacking?: string
  timeCommitted?: string
  packages: Package[]
  idInOrder: number
  recommendedPackageType?: PackageType
  maxWeightG?: number
  deliverySiteDistance?: number
}

export interface ShipmentUnit {
  id: number
  shipmentId: string
  requestedProduct: RequestedProduct
  packaged: boolean
  unitId?: string
}

export enum ShipmentFilter {
  OPEN = 'open',
}

export interface ShipmentUnitsSkuGroup {
  sku: string
  totalQuantity: number
  quantityToPack: number
  shipmentUnits: ShipmentUnit[]
  units: BasicUnitWithProduct[]
  totalWeight: number
}
