export type FeatureFlag = boolean | number | string

export enum FeatureFlagName {
  CREDIT_CHECK_ENABLED = 'order__credit_check_enabled',
  FACILITY_PRODUCT_LIMITS_ENABLED = 'order__facility_product_limits_enabled',
  FACILITY_STOCK_ENABLED = 'order__facility_stock_enabled',
  INVENTORY_RETURNS_DRIVE_URL = 'data__inventory_returns_drive_url',
  PACKAGE_MIN_WEIGHT_G = 'package__min_weight_g',
  PACKAGE_WARNING_WEIGHT_G = 'package__warning_weight_g',
  PACKAGE_MAX_WEIGHT_G = 'package__max_weight_g',
  PACKAGE_EPOD_ENABLED = 'package__epod_enabled',
  PACKAGE_AUDIO_ALERT_TOGGLE_ENABLED = 'package__audio_alert_toggle_enabled',
  SCHEDULED_DELIVERY_WINDOW = 'order__scheduled_delivery_window',
  SMS_LANGUAGE_CODE = 'languages__sms_script_languages',
  ZEBRA_PRINTER_ENABLED = 'label_template__zebra_printer_enabled',
  SEARCH_BY_SKU_ENABLED = 'order__search_by_sku_enabled',
  PROGRAM_TAGGING_ENABLED = 'order__add_program_enabled',
  TRACK_BLOOD_USE = 'order__track_blood_use',
  CHECK_BOX_UNIT_WEIGHT = 'package__check_box_unit_weight',
  ACCEPTABLE_WEIGHT_VARIANCE_DECIMAL = 'package__acceptable_weight_variance_decimal',
  UNIFIED_LABEL_ENABLED = 'label_template__unified_package_label',
  CONTINUOUS_LABEL_ENABLED = 'label_template__zebra_continuous_label',
  CARTON_RECEIVE_ENABLED = 'receiving__carton_receive_enabled',
  SELECT_BLOOD_GROUP_ID_ENABLED = 'receiving__select_blood_group_id_enabled',
  EMERGENCY_ORDER_CONFIRMATION_ENABLED = 'order__emergency_order_confirmation_enabled',
  EMERGENCY_ORDER_CONFIRMATION_START = 'order__emergency_order_confirm_start_time',
  EMERGENCY_ORDER_CONFIRMATION_END = 'order__emergency_order_confirm_end_time',
  FULFILLMENT_OPERATIONS_SERVICE_DESK_LINK_ENABLED = 'utils__fulfillment_operations_service_desk_link_enabled',
  BILLABLE_TO_ENABLED = 'order__billable_to_enabled',
  PRIORITIES_V2_ENABLED = 'order__priorities_v2_enabled',
  FESTIVAL_ENABLED = 'utils__festival_enabled',
  SLOTTING_TOOL_REPORT_ENABLED = 'order__slotting_tool_report_enabled',

  // CB Deduplication refactor
  DEDUPLICATE_CB_PACKAGING_VIEW = 'deduplication__cb_packaging_view',
}

export interface UiConfig {
  countryCode: string
  nestId: number
  nestKey: string
  distributionCenterName: string
  ziplinePhoneNumber: string
  donationIdRegex: RegExp
  globalAlertMessage: string
  featureFlags: Record<string, FeatureFlag>
}

export interface AuthConfig {
  region: string
  userPoolId: string
  userPoolWebClientId: string
}
